/*
THEME: Bingo | Responsive Multipurpose Parallax HTML5 Template
VERSION: 1.0.0
AUTHOR: Themefisher

HOMEPAGE: https://themefisher.com/products/bingo-bootstrap-business-template/
DEMO: https://demo.themefisher.com/themefisher/bingo/
GITHUB: https://github.com/themefisher/Bingo-Bootstrap-Business-Template

WEBSITE: https://themefisher.com
TWITTER: https://twitter.com/themefisher
FACEBOOK: https://www.facebook.com/themefisher
*/
/*=== MEDIA QUERY ===*/
@import url("https://fonts.googleapis.com/css?family=Catamaran:400,500,600,700,700|Source+Sans+Pro:400,600");
body {
  line-height: 1.4;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: "Source Sans Pro", sans-serif;
  color: #848484;
  font-size: 16px;
  line-height: 1.65;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Catamaran", sans-serif;
  font-weight: 600;
}

.no-padding {
  padding: 0 !important;
}

.inline-block {
  display: inline-block;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  -webkit-transition: all .3s ease-in 0s;
  -moz-transition: all .3s ease-in 0s;
  -o-transition: all .3s ease-in 0s;
  transition: all .3s ease-in 0s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus, a:hover {
  color: #28ABE3;
}

.btn-main {
  background: #28ABE3;
  border: 1px solid #28ABE3;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 12px 40px;
  text-transform: uppercase;
  border-radius: 40px;
}

.btn-main:hover {
  color: #fff;
}

.btn-transparent {
  color: #fff;
  border: 1px solid #fff;
  display: inline-block;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 14px 35px;
  text-transform: uppercase;
  border-radius: 40px;
}

.btn-transparent:hover {
  color: #fff;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.section {
  padding: 100px 0;
}

.btn:focus {
  color: #ddd;
}

#home {
  position: relative;
}

.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-xs {
  padding: 40px 0;
}

#preloader {
  background: #fff;
  height: 100%;
  left: 0;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999999;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.parallax-section {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.preloader {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
}

.preloader span {
  position: absolute;
  display: block;
  bottom: 0;
  width: 9px;
  height: 5px;
  border-radius: 5px;
  background: #fff;
  -webkit-animation: preloader 2s infinite ease-in-out;
  animation: preloader 2s infinite ease-in-out;
}

.preloader span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.preloader span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

.preloader span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

.preloader span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}

.preloader span:nth-child(6) {
  left: 55px;
  -webkit-animation-delay: 1000ms;
  animation-delay: 1000ms;
}

@-webkit-keyframes preloader {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #28ABE3;
  }
  50%, 100% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
}

@keyframes preloader {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: #fff;
  }
  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #3498db;
  }
  50%, 100% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: #fff;
  }
}

.title {
  padding-bottom: 50px;
  overflow: hidden;
}

.title h2 {
  font-weight: 600;
  font-size: 35px;
  color: #232323;
}

.title p {
  color: #848484;
  margin: 0 auto;
}

.title.title-white h2 {
  color: #fff;
}

.border {
  height: 2px;
  margin: 20px auto 20px;
  position: relative;
  width: 80px;
  background: #28ABE3;
}

.bg-gray {
  background: #f9f9f9;
}

.header-bradcrumb {
  background: transparent;
}

.header-bradcrumb a, .header-bradcrumb .active {
  color: #cfcfcf;
  font-weight: 200;
  font-family: "Source Sans Pro", sans-serif;
}

.header-bradcrumb a:hover {
  color: #28ABE3;
}

.colors-switcher {
  width: 100px;
  padding: 20px 18px 14px 20px;
  position: fixed;
  z-index: 1993;
  top: 90px;
  right: 0;
  background-color: #fff;
  border-radius: 2px 0 0 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.colors-switcher #show-panel {
  position: absolute;
  left: -38px;
  top: 15px;
  width: 38px;
  height: 38px;
  z-index: 1992;
  display: block;
  background-color: #fff;
  border-radius: 2px 0 0 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: none;
  cursor: pointer;
}

.colors-switcher #show-panel i {
  color: #3D3D3D;
  font-size: 20px;
  line-height: 38px;
  margin-left: 10px;
}

.colors-list {
  margin: 0;
  padding: 0;
}

.colors-list li {
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 4px;
}

.colors-list li a {
  text-align: center;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  cursor: pointer;
}

.colors-switcher .colors-list a.light-red {
  background-color: #FF432E;
}

.colors-switcher .colors-list li a:hover {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.colors-switcher .colors-list a.light-red {
  background-color: #FF432E;
}

.colors-switcher .colors-list a.blue {
  background-color: #28ABE3;
}

.colors-switcher .colors-list a.light-blue {
  background-color: #69D2E7;
}

.colors-switcher .colors-list a.green {
  background-color: #5BB12F;
}

.colors-switcher .colors-list a.light-green {
  background-color: #BCCF02;
}

.colors-switcher .colors-list a.yellow {
  background-color: #FED136;
}

.slick-slide {
  outline: none;
}

.logo {
  display: inline-block;
}

.single-page-header {
  background-image: url("../images/about/about-header.jpg");
  background-size: cover;
  padding: 140px 0 70px;
  text-align: center;
  color: #fff;
  position: relative;
}

.single-page-header:before {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

/*=================================================================
  Navigation
==================================================================*/
.navigation {
  transition: .3s all;
}

.navigation .logo-default {
  display: none;
}

.navigation .dropdown-toggle::before, .navigation .dropdown-toggle::after {
  display: none;
}

.navigation .dropdown-toggle i {
  font-size: 10px;
  margin-left: 2px;
  vertical-align: 2px;
}

.navigation .navbar-light .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  padding: 20px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: .2s ease-in-out 0s;
}

.navigation .navbar-light .navbar-nav .nav-item .nav-link:hover, .navigation .navbar-light .navbar-nav .nav-item .nav-link:active, .navigation .navbar-light .navbar-nav .nav-item .nav-link:focus {
  background: none;
  color: #28ABE3;
}

.navigation .navbar-light .navbar-nav .nav-item.active .nav-link {
  color: #28ABE3;
}

@media (max-width: 991px) {
  .navigation .nav-link {
    padding: 6px !important;
  }
  .navigation .navbar-collapse.show {
    overflow-y: auto;
    max-height: calc(100vh - 55px);
  }
}

.navigation .dropdown-submenu .dropdown-menu {
  margin: 0;
  background-color: #131313;
}

@media (min-width: 992px) {
  .navigation .dropdown-submenu .dropdown-menu {
    left: 100%;
    top: -5px;
  }
  .navigation .dropleft .dropdown-menu {
    left: auto;
    right: 100%;
    margin: 0;
    top: -5px;
  }
  .navigation .dropdown-submenu .dropdown-menu {
    background-color: #000;
  }
}

.navigation .dropdown:hover > .dropdown-menu {
  visibility: visible;
  opacity: 1;
}

.navigation .dropdown-menu {
  border: 0;
  border-radius: 0;
  top: 80%;
  left: 5px;
  display: block;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  background: #000;
  transition: .3s ease;
}

.navigation .dropdown-menu li:first-child {
  margin-top: 12px;
}

.navigation .dropdown-menu li:last-child {
  margin-bottom: 12px;
}

@media (max-width: 991px) {
  .navigation .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    text-align: center;
    transition: 0s;
  }
}

@media (max-width: 991px) {
  .navigation .dropdown-menu.show {
    display: block;
  }
  .navigation .dropdown-menu {
    float: left;
    width: 100%;
  }
}

.navigation .dropdown-item {
  color: rgba(255, 255, 255, 0.8);
  transition: .2s ease;
  padding: 8px 22px;
}

.navigation .dropdown-item:focus, .navigation .dropdown-item:hover {
  color: #fff;
  background: transparent;
}

@media (max-width: 991px) {
  .navigation {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    background: #fff;
  }
  .navigation .navbar-light .navbar-nav .nav-item .nav-link {
    color: #000;
  }
  .navigation .logo-white {
    display: none;
  }
  .navigation .logo-default {
    display: block;
  }
  .navigation .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
}

.sticky-header {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
}

.sticky-header .navbar-light .navbar-nav .nav-item .nav-link {
  color: #000;
}

.sticky-header .logo-white {
  display: none;
}

.sticky-header .logo-default {
  display: block;
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
}

.navbar-brand h1 {
  margin: 0;
}

.hero-area {
  background-image: url("../images/slider/slider-bg-1.jpg");
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-area:before {
  content: '';
  background: rgba(0, 0, 0, 0.63);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-area .block {
  color: #fff;
}

.hero-area .block h1 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.hero-area .block p {
  color: #fff;
  width: 50%;
  margin-bottom: 20px;
}

.hero-area .block .btn-main {
  margin-right: 8px;
}

.hero-area .block .btn-main:hover {
  opacity: .8;
}

.hero-area-video {
  height: 100vh;
  position: inherit !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-area-video .block {
  color: #fff;
  text-align: center;
}

.hero-area-video .block h1 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-area-video .block p {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.hero-area-video .block .btn-main {
  margin-top: 20px;
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.slider-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.slider-item::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
}

.slider-item .container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.slider-item h1 {
  color: #fff;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .slider-item h1 {
    font-size: 48px;
    line-height: 52px;
  }
}

@media (max-width: 480px) {
  .slider-item h1 {
    font-size: 38px;
    line-height: 46px;
  }
}

.slider-item p {
  color: #fff;
  margin-bottom: 20px;
}

.hero-slider {
  overflow-x: hidden;
}

.hero-slider .prevArrow {
  left: -100px;
}

.hero-slider .nextArrow {
  right: -100px;
}

@media (max-width: 768px) {
  .hero-slider .prevArrow,
  .hero-slider .nextArrow {
    display: none !important;
  }
}

.hero-slider:hover .prevArrow {
  left: 20px;
}

.hero-slider:hover .nextArrow {
  right: 20px;
}

/* slick style */
.slick-slide {
  outline: 0;
}

.slick-slide img {
  display: unset;
}

/* slick arrows */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 70px;
  width: 70px;
  background: rgba(40, 171, 227, 0.5);
  color: #fff;
  border: 0;
  line-height: 70px;
  font-size: 35px;
  transition: .2s ease;
  border-radius: 50%;
  cursor: pointer;
}

.slick-arrow::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: .2s ease;
}

.slick-arrow::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 25px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  transition: .2s ease;
}

.slick-arrow:focus {
  outline: 0;
}

.slick-arrow:hover {
  background: #28ABE3;
}

.prevArrow {
  left: 0px;
}

.prevArrow::before {
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  right: 35px;
}

.prevArrow::after {
  right: 20px;
}

.nextArrow {
  right: 0px;
}

.nextArrow::before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  left: 35px;
}

.nextArrow::after {
  left: 20px;
}

/* /slick arrows */
/*=================================================================
  About us section
==================================================================*/
.about {
  padding: 100px 0;
  background: #fff;
}

.about .btn-about {
  background: #28ABE3;
}

.about h4 {
  margin-top: 30px;
}

.about .feature-list {
  margin-top: 10px;
}

.about .feature-list li {
  width: 48%;
  display: inline-block;
  color: #666;
  font-size: 13px;
  font-family: "Catamaran", sans-serif;
  margin-bottom: 5px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .about .feature-list li {
    width: auto;
    margin-right: 20px;
  }
}

.about .feature-list li i {
  color: #28ABE3;
  margin-right: 8px;
}

/*=================================================================
  About us 2 section
==================================================================*/
.about-2 {
  background: #F7F7F7;
}

.about-2 .checklist {
  padding-left: 30px;
}

.about-2 .checklist li {
  position: relative;
  margin-bottom: 15px;
  font-size: 16px;
  color: #777;
  font-family: "Source Sans Pro", sans-serif;
}

.about-2 .checklist li:before {
  position: absolute;
  margin-right: 12px;
  margin-left: -45px;
  content: "\f375";
  font-family: "themefisher-font";
  opacity: 1;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 20px;
}

/*=================================================================
 Call To Action 1
==================================================================*/
.call-to-action {
  padding: 100px 0;
  background: url("../images/call-to-action/call-to-action-bg-2.jpg");
  background-size: cover;
  color: #fff;
}

.call-to-action h2 {
  line-height: 1.5;
}

.call-to-action p {
  color: #fff;
}

.call-to-action .btn-main {
  margin-top: 20px;
}

/*=================================================================
 Call To Action 2
==================================================================*/
.call-to-action-2 {
  padding: 100px 0;
  background-image: url("../images/call-to-action/call-to-action-bg.jpg");
  background-size: cover;
  color: #fff;
}

.call-to-action-2 h2 {
  line-height: 1.5;
}

.call-to-action-2 p {
  color: #fff;
}

.call-to-action-2 .btn-main {
  margin-top: 20px;
}

/*=================================================================
  Contact
  ==================================================================*/
.contact-us {
  padding: 100px 0 50px 0;
}

.contact-form {
  margin-bottom: 40px;
}

.contact-form .form-control {
  background-color: transparent;
  border: 1px solid #dedede;
  box-shadow: none;
  height: 45px !important;
  color: #0c0c0c;
  height: 38px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  border-radius: 0;
}

.contact-form input:hover,
.contact-form textarea:hover,
.contact-form #contact-submit:hover {
  border-color: #28ABE3;
}

.contact-form #contact-submit {
  border: none;
  padding: 15px 0;
  width: 100%;
  margin: 0;
  background: #28ABE3;
  color: #fff;
  border-radius: 0;
}

.contact-form textarea.form-control {
  padding: 10px;
  height: 120px !important;
  outline: none;
}

.contact-details .contact-short-info {
  margin-top: 15px;
}

.contact-details .contact-short-info li {
  margin-bottom: 6px;
}

.social-icon {
  margin-top: 20px;
}

.social-icon ul li {
  display: inline-block;
  margin-right: 10px;
}

.social-icon ul li a {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #dedede;
  text-align: center;
}

.social-icon ul li a:hover {
  background: #28ABE3;
  color: #fff;
  border: 1px solid #28ABE3;
}

.social-icon ul li a:hover i {
  color: #fff;
}

.social-icon ul li a i {
  color: #55606a;
  display: inline-block;
  font-size: 20px;
  line-height: 50px;
  margin: 0;
}

.google-map {
  position: relative;
}

.google-map .map_canvas {
  height: 400px;
  color: white;
}

/*=================================================================
  Item counter section
==================================================================*/
.counters-item {
  padding-bottom: 30px;
  color: #444;
  position: relative;
}

.counters-item.kill-border:before {
  background: none;
}

.counters-item:before {
  background: #e5e5e5;
  content: "";
  margin-top: -27.5px;
  height: 55px;
  width: 1px;
  position: absolute;
  right: -15px;
  top: 50%;
}

.counters-item > div {
  margin: 6px 0;
}

.counters-item span {
  display: inline;
  font-size: 35px;
  font-weight: 700;
  line-height: 50px;
}

.counters-item i {
  font-size: 40px;
  display: inline-block;
  color: #28ABE3;
}

.counters-item h3 {
  font-size: 18px;
  margin: 0;
  color: #666;
}

/*=================================================================
  Pricing section
==================================================================*/
.pricing-table {
  padding: 100px 0;
  padding-bottom: 70px;
  background: url("../images/pricing-bg.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.pricing-table .title p {
  color: #fff;
}

.pricing-table:before {
  background: rgba(0, 0, 0, 0.62);
  position: absolute;
  z-index: 999;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pricing-table .container {
  position: relative;
  z-index: 999;
}

.pricing-table .pricing-item {
  padding: 40px 55px 65px;
  background: #fff;
  margin-bottom: 30px;
}

.pricing-table .pricing-item a.btn-main {
  text-transform: uppercase;
  margin-top: 20px;
}

.pricing-table .pricing-item li {
  font-weight: 400;
  padding: 10px 0;
  color: #666;
}

.pricing-table .pricing-item li i {
  margin-right: 6px;
}

.pricing-table .price-title {
  padding: 30px 0 20px;
}

.pricing-table .price-title > h3 {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 15px;
  text-transform: uppercase;
}

.pricing-table .price-title > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 5px;
}

.pricing-table .price-title .value {
  color: #28ABE3;
  font-size: 50px;
  padding: 10px 0;
}

/*=================================================================
  Portfolio section
==================================================================*/
.portfolio-filter {
  margin-bottom: 40px;
  text-align: center;
}

.portfolio-filter button {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 8px 20px;
  text-transform: uppercase;
  background: #28ABE3;
  border: none;
  color: #fff;
  outline: none;
  margin: 6px 4px;
}

.portfolio-filter button.active {
  background: #28ABE3;
  color: #fff;
  border-radius: 50px;
}

.portfolio-block {
  position: relative;
  margin-bottom: 30px;
}

.portfolio-block:before {
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.55);
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  content: '';
}

.portfolio-block:hover:before {
  opacity: 1;
}

.portfolio-block:hover .caption h4 {
  top: 45%;
}

.portfolio-block:hover .caption {
  opacity: 1;
  top: 55%;
}

.portfolio-block .caption {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  z-index: 9;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.portfolio-block .caption h4 {
  font-size: 16px;
}

.portfolio-block .caption h4 a {
  color: #fff;
}

.portfolio-block .caption .search-icon {
  background: #28ABE3;
  color: #fff;
  display: inline-block;
  height: 45px;
  width: 45px;
  font-size: 17px;
  border-radius: 30px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 47px;
}

/*=================================================================
  Services section
==================================================================*/
.services {
  padding: 100px 0 130px;
}

.services .service-block.color-bg {
  background: #28ABE3;
  color: #fff;
}

.services .service-block.color-bg i, .services .service-block.color-bg h3, .services .service-block.color-bg p {
  color: #fff;
}

.services .service-block .service-icon {
  margin-bottom: 30px;
}

.services .service-block i {
  font-size: 75px;
  color: #28ABE3;
}

.services .service-block h3 {
  color: #333;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.services .service-block p {
  font-size: 14px;
  color: #666;
}

/*=================================================================
  Services section 2
==================================================================*/
.service-2 .service-item {
  border: 1px solid #eee;
  margin-bottom: 30px;
  padding: 50px 20px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.service-2 .service-item:hover {
  box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 5px 65px 0 rgba(0, 0, 0, 0.15);
}

.service-2 .service-item:hover i {
  background: #fff;
  color: #28ABE3;
}

.service-2 .service-item i {
  font-size: 30px;
  display: inline-block;
  background: #28ABE3 none repeat scroll 0 0;
  border-radius: 30px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  height: 55px;
  line-height: 55px;
  margin-bottom: 20px;
  width: 55px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/*=================================================================
  Our skills
==================================================================*/
.team-skills .progress-block {
  margin-top: 40px;
}

.team-skills .progress-block span {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
}

.team-skills .progress-block .progress {
  box-shadow: none;
  border-radius: 0;
  height: 10px;
}

.team-skills .progress-block .progress .progress-bar {
  background: #28ABE3;
  box-shadow: none;
}

.clients-logo-slider {
  margin-top: 50px;
}

/*=================================================================
  Step section
==================================================================*/
.development-steps .step {
  padding: 80px 20px;
}

.development-steps .step h4 {
  font-size: 20px;
  font-weight: 600;
}

.development-steps .step h4 i {
  font-size: 72px;
  font-weight: 500;
  margin-right: 10px;
}

.development-steps .step p {
  color: #cccccc;
  font-size: 14px;
  line-height: 22px;
}

.development-steps .step-1 {
  background-color: #f2f6f8;
}

.development-steps .step-1 h4 {
  color: #212121;
}

.development-steps .step-1 p {
  color: #838383;
}

.development-steps .step-2 {
  background-color: #28ABE3;
}

.development-steps .step-2 h4, .development-steps .step-2 p {
  color: #fff;
}

.development-steps .step-3 {
  background: #198dbf;
}

.development-steps .step-3 h4, .development-steps .step-3 p {
  color: #fff;
}

.development-steps .step-4 {
  background: #167da9;
}

.development-steps .step-4 h4, .development-steps .step-4 p {
  color: #fff;
}

/*=================================================================
  Our Team
==================================================================*/
.team {
  padding: 100px 0;
  padding-bottom: 70px;
  background: #F6F6F6;
}

.team .team-member {
  background: #fff;
  margin-bottom: 30px;
}

.team .team-member .member-content {
  padding: 25px 0;
}

.team .team-member .member-content h3 {
  font-size: 20px;
  margin: 0 0 5px;
  color: #333;
}

.team .team-member .member-content span {
  font-size: 14px;
  color: #555;
}

.team .team-member .member-content p {
  color: #777;
  margin-top: 10px;
  padding: 0 15px;
}

.team .member-photo {
  overflow: hidden;
  position: relative;
}

.team .member-photo:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.team .member-photo img {
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
}

.team .member-photo .mask {
  background: rgba(40, 171, 227, 0.7);
  position: absolute;
  width: 100%;
  bottom: 0;
  top: auto;
  opacity: 0;
  filter: alpha(opacity=0);
  left: 0;
  right: 0;
  cursor: pointer;
  padding: 0;
  height: 80px;
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.team .member-photo .mask ul {
  text-align: center;
  position: relative;
  margin-top: 22px;
}

.team .member-photo .mask ul li {
  display: inline-block;
  margin-right: 7px;
}

.team .member-photo .mask ul li a {
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #fff;
  display: block;
  font-size: 20px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}

.team .member-photo .mask ul li a::hover {
  background-color: #6cb670;
  border: 1px solid transparent;
}

.team .member-photo:hover .mask {
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

/*=================================================================
  Testimonial section
==================================================================*/
.testimonial {
  padding-top: 100px;
  background: #28ABE3;
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 80px;
}

.testimonial:before {
  content: "";
  display: block;
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 0;
  height: 145px;
  background-color: white;
}

.testimonial .item i {
  font-size: 60px;
  color: #fff;
  margin-bottom: 50px;
  display: inline-block;
}

.testimonial .item .client-details {
  padding-bottom: 30px;
}

.testimonial .item .client-details p {
  font-size: 17px;
  color: #fff;
  font-weight: 200;
  padding: 0 10%;
}

.testimonial .item .client-thumb img {
  display: inline-block;
  border-radius: 100%;
  width: 120px;
  height: auto;
  margin-bottom: 20px;
}

/*=================================================================
  Latest Posts
==================================================================*/
.blog {
  padding-bottom: 60px;
  padding-top: 80px;
  background: #F6F6F6;
}

.posts {
  padding-bottom: 40px;
}

.post-item {
  background: #fff;
  margin-bottom: 30px;
}

.post-item .content {
  padding: 20px;
}

.post-item .content h3 a {
  color: #000;
}

.post-item .content p {
  color: #757575;
  margin: 10px 0;
}

.post-item .content .btn-main {
  padding: 12px 25px;
  margin: 15px 0;
}

/* 8.2.1 Blog Details */
.blog-details h3 {
  font-size: 30px;
  color: #000;
  margin-bottom: 10px;
}

/* 8.2.2 Post Content */
.post-content ul {
  margin-bottom: 20px;
}

.post-content ul li {
  color: #aaa;
}

.post-content ul li a {
  color: #aaa;
}

.post-content p {
  margin-bottom: 30px;
}

.post-content blockquote {
  border-left: 2px solid #28ABE3;
  padding: 40px 35px;
  margin-bottom: 30px;
  background: #eeeeee;
}

.post-content-share {
  margin-bottom: 100px;
}

.post-content-share li a {
  height: 45px;
  width: 45px;
  line-height: 45px;
  border: 1px solid #ddd;
  text-align: center;
  background: #fff;
  transition: .3s ease;
  margin-right: 10px;
  color: #000;
  display: inline-block;
}

.post-content-share li a:hover {
  background: #28ABE3;
  border-color: #28ABE3;
  color: #fff;
}

/* 8.2.3 Comment List */
.comment-list {
  padding-left: 0;
  margin-bottom: 70px;
}

.comment-list-item {
  padding: 25px 0;
  position: relative;
  display: flex;
  border-bottom: 1px solid #ddd;
}

.comment-list-item:last-child {
  border: 0;
}

.comment-list-item-image {
  margin-right: 20px;
}

.comment-list-item-content h5 {
  color: #000;
  margin-bottom: 5px;
}

.comment-list-item-content h6 {
  color: #000;
  margin-bottom: 10px;
}

.comment-list-item-content p {
  margin-bottom: 5px;
}

.comment-list-item .comment-btn {
  position: absolute;
  top: 25px;
  right: 0;
  background: #fff;
  padding: 10px 30px;
  border: 1px solid #ddd;
  text-transform: capitalize;
  color: #000;
  font-size: 15px;
}

@media (max-width: 575px) {
  .comment-list-item .comment-btn {
    position: unset;
    margin-top: 15px;
    display: inline-block;
  }
}

.comment-list-item .comment-btn:hover {
  background: #28ABE3;
  color: #fff;
}

/* 8.2.4 Comment Form */
.comment-form {
  margin-top: 40px;
}

.comment-form .form-control {
  background-color: transparent;
  border: 1px solid #dedede;
  box-shadow: none;
  height: 45px !important;
  color: #0c0c0c;
  height: 38px;
  font-size: 14px;
  border-radius: 0;
  margin-bottom: 20px;
}

.comment-form textarea.form-control {
  height: 200px !important;
}

.comment-form .btn-primary {
  background: #28ABE3;
  border-radius: 0;
  padding: 10px 20px;
  border: 0;
}

/* 8.2.5 Widget Style */
.widget {
  margin-bottom: 30px;
}

.widget h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.widget-search {
  position: relative;
}

.widget-search .form-control {
  border: 1px solid #dedede;
  border-radius: 0;
  height: 50px;
}

.widget-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  background: #28ABE3;
  border: 0;
}

.widget-search-btn i {
  color: #fff;
}

.widget-categories-list {
  padding-left: 0px;
}

.widget-categories-list li {
  border-bottom: 1px solid #ddd;
}

.widget-categories-list li:last-child {
  border: 0;
}

.widget-categories-list li a {
  display: block;
  padding: 15px 0;
  color: #000;
  font-size: 15px;
  transition: .3s ease;
}

.widget-categories-list li a i {
  font-size: 10px;
  margin-right: 10px;
}

.widget-categories-list li a:hover {
  background: #28ABE3;
  color: #fff;
  padding-left: 20px;
}

.widget-post-list {
  padding-left: 0;
}

.widget-post-list-item {
  padding: 15px 0;
  display: flex;
}

.widget-post-image {
  margin-right: 10px;
  height: 90px;
  width: 130px;
  overflow: hidden;
}

.widget-post-image img {
  height: 90px;
  width: auto;
}

.widget-post-content h5 {
  font-size: 18px;
  margin-bottom: 10px;
  transition: .3s ease;
  color: #000;
}

.widget-post-content h5:hover {
  color: #28ABE3;
}

/*=================================================================
  Single Blog Page
==================================================================*/
#blog-banner {
  padding-bottom: 100px;
  padding-top: 150px;
}

#blog-page {
  margin: 0 0 40px;
}

.post-meta {
  font-size: 13px;
  margin: 20px 0 0;
  padding: 0 20px 10px;
}

.post-meta a {
  color: #28ABE3;
}

.post-meta > span {
  color: #666;
  border-right: 1px solid #dedede;
  display: inline-block;
  font-size: 13px;
  margin: 10px 10px 0 0;
  padding-right: 10px;
}

.post-meta > span:last-child {
  border-right: 0;
}

.post-meta > span i {
  margin-right: 5px;
}

.post-excerpt {
  padding: 0 20px;
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt blockquote {
  line-height: 22px;
  margin: 20px 0;
  font-size: 16px;
}

.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: #28ABE3;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: #28ABE3;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #28ABE3;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #28ABE3;
}

.comment-reply-form textarea, .comment-reply-form .btn-main {
  height: auto;
}

/*=================================================================
  Footer section
==================================================================*/
.top-footer {
  background-color: #222222;
  border-top: 1px solid #404040;
  padding: 100px 0 80px;
}

.top-footer h3 {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.top-footer p {
  font-size: 13px;
  line-height: 25px;
  color: #bdbdbd;
  font-weight: 300;
  letter-spacing: 1px;
  padding-right: 20px;
}

.top-footer li a {
  font-size: 13px;
  line-height: 30px;
  color: #bdbdbd;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: color .3s;
  font-family: "Source Sans Pro", sans-serif;
  display: block;
}

.top-footer li a:hover {
  color: #28ABE3;
}

.footer-bottom {
  text-align: center;
  background-color: #1b1b1b;
  border-top: 1px solid #2c2c2c;
  padding: 30px 0;
}

.footer-bottom h5 {
  font-size: 12px;
  line-height: 1;
  color: #888888;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 7px;
  margin-bottom: 3px;
}

.footer-bottom h6 {
  font-size: 11px;
  line-height: 15px;
  color: #888888;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.footer-bottom a {
  color: #28ABE3;
}

.page-404 {
  display: flex;
  align-items: center;
  height: 100vh;
}

.page-404 h1 {
  font-size: 200px;
}

/*============================================================
	For Small Desktop
==============================================================*/
@media (min-width: 700px) and (max-width: 1090px) {
  .counters-item:before {
    display: none;
  }
}

@media (min-width: 980px) and (max-width: 1150px) {
  /* parallax section */
  .parallax-section {
    background-position: center top !important;
  }
  /* about us */
  .feature-desc h3 {
    margin-top: 0;
  }
  /* our team */
  .team-mate {
    margin-bottom: 30px;
  }
  .mask ul {
    top: 85px;
  }
  .mask ul li {
    margin: 0 5px;
  }
  .team-mate > h4 {
    margin-bottom: 10px;
  }
  .team-mate img {
    margin: 0 auto;
  }
  .member-info ul li {
    margin-right: 10px;
  }
  /* testimonial */
  .client-info {
    width: 55%;
  }
  /* blog page */
  .tab-post-nav li a {
    padding: 15px 8px;
  }
  /* single blog page */
  .author-bio h5 {
    margin-left: 70px;
  }
}

/*============================================================
	Tablet (Portrait) Design for a width of 768px
==============================================================*/
@media (min-width: 768px) and (max-width: 979px) {
  .counters-item:before {
    display: none;
  }
  /* parallax section */
  .parallax-section {
    background-position: center top !important;
  }
  /* navigation */
  .navbar-inverse .navbar-nav li a {
    padding: 20px 10px;
  }
  /* about us */
  .wrap-about {
    margin: 0 0 75px;
  }
  .about-content {
    margin: 0 auto;
    width: 60%;
  }
  /* fun facts */
  #counter {
    padding-bottom: 50px;
  }
  .counters-item {
    margin-bottom: 30px;
  }
  /* our team */
  .team-mate {
    margin: 0 auto 30px;
    width: 280px;
  }
  /* our skills */
  .skill-chart {
    margin: 0 auto 40px;
    width: 300px;
  }
  .skill-chart {
    margin: 0 auto 40px;
    width: 90%;
  }
  .skill-chart > h3 {
    margin: 15px 0;
  }
  /* pricing table */
  .pricing {
    margin: 0 auto 30px;
    width: 300px;
  }
  /* testimonial */
  .client-info {
    width: 75%;
  }
  /* latest posts */
  .note {
    margin-bottom: 30px;
  }
  /* blog page */
  .tab-post-nav li a {
    padding: 10px;
  }
  /* singla blog page */
  .author-social {
    margin-left: 0;
  }
}

/*============================================================
	Mobile (Portrait) Design for a width of 320px
==============================================================*/
@media only screen and (max-width: 767px) {
  .counters-item:before {
    display: none;
  }
  .clients-logo-slider {
    text-align: center;
  }
  /* parallax section */
  .parallax-section {
    background-position: center top !important;
  }
  /* h1 font size home slider */
  h1 {
    font-size: 30px;
  }
  .wrap-about {
    margin: 0 auto 80px;
    width: 85%;
  }
  .feature-desc a {
    margin: 5px 5px 5px 0;
  }
  /* fun facts */
  /* services */
  #services .title {
    padding-bottom: 60px;
  }
  /* our skills */
  .skill-chart {
    margin: 0 auto 40px;
    width: 280px;
  }
  /* our team */
  .team-mate {
    margin: 0 auto 31px;
    width: 280px;
  }
  .team-mate img {
    margin: 0 auto;
  }
  .member-info ul {
    text-align: center;
    margin-bottom: 10px;
  }
  /* pricing table */
  .pricing {
    margin: 0 auto 20px;
    width: 280px;
  }
  /* portfolio */
  .portfolio-filter ul li a {
    margin: 0 10px 10px 0;
    padding: 5px 10px;
  }
  /* testimonials */
  .sub-title h3 {
    font-size: 18px;
  }
  .client-info {
    width: 100%;
  }
  /* latest posts */
  .note {
    margin-bottom: 30px;
  }
  .note .excerpt {
    padding: 0 20px 20px;
  }
  .all-post {
    margin: 20px 0 30px;
  }
  /* contact */
  .contact-info {
    margin: 0 0 30px;
  }
  #map-canvas {
    height: 250px;
  }
  /* footer */
  .social-icon ul li,
  .social-icon ul li:first-child {
    margin: 15px;
  }
  .social-icon ul li a {
    width: 50px;
    height: 50px;
  }
  .social-icon ul li a i {
    font-size: 24px;
    line-height: 50px;
  }
  .blog-title h1 {
    font-size: 28px;
  }
  /* blog page */
  #blog-page {
    margin: 0;
  }
  #blog-posts {
    margin: 0 0 40px;
  }
  .post-excerpt h3 {
    font-size: 20px;
  }
  .post-pagination ul li {
    margin: 0 10px 10px 0;
  }
  .tab-post-nav li a {
    padding: 8px 5px;
  }
  /* single blog page */
  .author-social {
    margin-left: 0;
  }
  .author-avatar {
    margin-right: 10px;
  }
}

/*============================================================
	Mobile (Landscape) Design for a width of 480px
==============================================================*/
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .title h2 {
    font-size: 32px;
  }
  .counter .counter-title h2 {
    font-size: 35px;
  }
  /* about us */
  .wrap-about {
    margin: 0 auto 75px;
    width: 70%;
  }
  /* our team */
  .team-mate {
    margin: 0 auto 30px;
    width: 280px;
  }
  .team-mate img {
    margin: 0 auto;
  }
  /* portfolio */
  .og-grid li {
    width: 245px;
  }
  /* skills */
  .skill-chart {
    margin: 0 auto 40px;
    width: 300px;
  }
  /* pricing table */
  .pricing {
    margin: 0 auto 30px;
    width: 350px;
  }
  /* testimonials */
  .client-info {
    width: 74%;
  }
  /* latest posts */
  .note {
    margin: 0 auto 30px;
    width: 75%;
  }
  /* contact */
  .contact-info {
    width: 100%;
  }
  /* blog page */
  .tab-post-nav li a {
    padding: 10px 15px;
  }
}
